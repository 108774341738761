import React from 'react';
import './privacyPolicy.scss';

const PrivacyPolicy: React.FC = () => {
  return (
    <div className="privacy_policy_container">
      <div className="privacy_policy">


        <h1>Privacy policy</h1>
        <h2>Introduction</h2>
        <p>
          Urban Jungle Care ("we", "us", or "our") is committed to protecting the privacy of our users ("you" or
          "your").
          This privacy policy explains how we collect, use, disclose, and safeguard your information when you use our
          mobile application ("App"). Please read this privacy policy carefully. If you do not agree with the terms of
          this privacy policy, please do not access the App.
        </p>

        <h2>Data use</h2>
        <p>
          We use your data solely for the purpose of providing our services.
          Access to your data is strictly limited to you as an authenticated user, ensuring that only you can view or
          handle your personal information.
          Our system is designed to protect your privacy and secure your personal data from unauthorized access.
          The types of data we process:
        </p>

        <ul>
          <li>
            <strong>Email Address:</strong>
            <ul>
              <li>Purpose: Used for authentication.</li>
              <li>Usage: To create and manage user accounts and provide login functionality.</li>
            </ul>
          </li>
          <li>
            <strong>Device ID:</strong>
            <ul>
              <li>Purpose: Used for authentication without a profile.</li>
              <li>Usage: Stored in an salted hash to uniquely identify users without they have to create an account.
              </li>
            </ul>
          </li>
          <li>
            <strong>Images:</strong>
            <ul>
              <li>Purpose: Used for plant recognition and identification services.</li>
              <li>Usage: To identify plants and provide care advice.</li>
            </ul>
          </li>
          <li>
            <strong>BLE Sensor Address:</strong>
            <ul>
              <li>Purpose: Used for reading data from Bluetooth Low Energy (BLE) sensors.</li>
              <li>Usage: To collect sensor data necessary for plant care analysis.</li>
            </ul>
          </li>
          <li>
            <strong>BLE Sensor Metrics:</strong>
            <ul>
              <li>Purpose: Collected from BLE sensors.</li>
              <li>Usage: Analysis to provide care advice and monitor plant health.</li>
            </ul>
          </li>
        </ul>


        <h2>Data Handling and Security</h2>
        <p>
          We implement robust technical and organizational measures to protect your personal information against
          unauthorized access, loss, or misuse. Our security practices include secure storage solutions.
        </p>

        <h2>Data Retention and Deletion</h2>
        <p>
          We retain your personal information only for as long as necessary to fulfill the purposes for which it was
          collected and to comply with legal and regulatory requirements. Once your data is no longer needed, we will
          securely delete it. If you choose to delete your profile, all associated personal information will be removed
          from our systems in accordance with our data deletion procedures.
        </p>

        <h2>Disclosure of Information</h2>
        <p>
          We do not sell, trade, or otherwise transfer your personal information to outside parties without your
          consent.
        </p>

        <h2>Service Providers</h2>
        <p>
          We may share your information with third-party service providers who perform services on our behalf.
          For example, we may use plant identification services to enhance the functionality of our app.
          These service providers are bound by confidentiality agreements and are not permitted to use your images for
          any other purpose.
        </p>

        <h2>Legal Requirements</h2>
        <p>
          We may disclose your information if required to do so by law or in response to valid requests by public
          authorities (e.g., a court or a government agency).
        </p>
        <p>
          We may update this privacy policy from time to time. We will notify you of any changes by posting the new
          privacy policy on our website and updating the effective date at the top of this page. We encourage you to
          review this
          privacy policy periodically to stay informed about how we are protecting your information.
          Your privacy is important to us, and we are committed to protecting your personal information.
        </p>

        <h2>Contact Us</h2>
        <p>
          If you have any questions or concerns about this privacy policy or our data practices, please contact us at <a
          href="mailto:info@urbanjunglecare.com">info@urbanjunglecare.com</a>.
        </p>

      </div>
    </div>
  );
};

export default PrivacyPolicy;
